import {SubsType} from '../../redux/types';
import media from '../../assets/IONNYK-discovery wall website.jpeg';
import media_free from '../../assets/Taste of Ionnyk.jpeg';

export const productInfos = {
  [SubsType.TASTE]: {
    order: 1,
    subscriptionType: SubsType.TASTE, // FIXME
    name: 'Taste of Ionnyk',
    desc: [
      'Up to 10 curated artworks changing each months !',
      'Access to special APP features: Buy Limited Editions, Schedule artworks',
      'Free of charge, included with you new frame',
    ],
    media: media_free,
  },
  [SubsType.DISCOVERY]: {
    /*
    Upgrade your experience & get full access to hundreds art pictures in our ever growing gallery for only 12,99€ per month.


     */
    order: 2,
    name: 'Discovery',
    subscriptionType: SubsType.DISCOVERY, // FIXME
    desc: [
      'Upgrade your Taste of Ionnyk experience ',
      [
        // 'One month of free trial',
        'Full access to 1000+ artworks in our ever growing gallery', // a rich catalog of
        'Various Artists universes and emotions (Nature, Africa, Portraits, Landscapes, etc.)',
        'New artworks, artists, collections each months',
        'Membership granting access to special events, contests, VIP passes to Art Fairs, etc.',
      ],
      // 'One month of free trial',
      'Cancel anytime *',
    ],
    media,
  },
};
