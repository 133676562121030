import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {UserProfile, UserProfileDTOIn, UserProfileDTOOut} from '../types';
import {
  APIErrorClient,
  authApi,
  handleNetworkError,
  initialRequestStatus,
  installBasicAuthHeader,
  RequestStatus,
  UNKNOWN_ERROR_KEY,
} from '../../api';
import {reconfirmEmail} from '../signinSlice';
import _ from 'lodash';
import {generateBasicToken, sleep} from '../../utils';

export const fetchProfileAsync = createAsyncThunk<
  UserProfileDTOIn, // output
  number, // input
  {rejectValue: APIErrorClient} // error output
>('profile/fetch', async (userId: number, thunkAPI) => {
  try {
    const response = await authApi.get(`authorization`); // ALT GET '/profile'
    return response.data as UserProfileDTOIn;
  } catch (e) {
    return thunkAPI.rejectWithValue(
      handleNetworkError('loginAsync', e, userId) as APIErrorClient,
    );
  }
});

export const updateProfileAsync = createAsyncThunk<
  UserProfileDTOIn, // output
  UserProfileDTOOut, // input
  {rejectValue: APIErrorClient} // error output
>('profile/update', async (userData: UserProfileDTOOut, thunkAPI) => {
  try {
    const response = await authApi.put(`profile`, userData);

    // update auth data - HTTP 200
    if (response.data?.email && userData.type === 'EMAIL') {
      // @ts-ignore
      const token = generateBasicToken(userData.email, userData.password);
      installBasicAuthHeader(token); // install it first to override previous login
      localStorage.removeItem('token');
      localStorage.setItem('token', token);
      console.log({token});
      // email has changed, we must update locally the 'to be confirmed' state
      //thunkAPI.dispatch(reconfirmEmail()); // FIXME re-enable this code when the confirmation email is ready on the backend side
    }
    if (userData.type === 'PASSWORD') {
      const token = generateBasicToken(
        // @ts-ignore
        thunkAPI.getState().auth.email,
        // @ts-ignore
        userData.newPassword,
      );
      installBasicAuthHeader(token); // install it first to override previous login
      localStorage.removeItem('token');
      localStorage.setItem('token', token);
      console.log({token});
    }

    return response.data as UserProfileDTOIn;
  } catch (e) {
    return thunkAPI.rejectWithValue(
      handleNetworkError(
        'updateProfileAsync',
        e,
        _.omit(userData, 'type'),
      ) as APIErrorClient,
    );
  }
});

interface ProfileState extends RequestStatus, Partial<UserProfile> {}

const initialState: ProfileState = {
  ...initialRequestStatus,
};

export const userProfileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (bd) => {
    bd.addCase(fetchProfileAsync.pending, (state, action) => {
      state.loading = true;
    });
    bd.addCase(fetchProfileAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
    });
    bd.addCase(fetchProfileAsync.rejected, (state, action) => {
      state.loading = false;
    });
    // UPDATE
    bd.addCase(updateProfileAsync.pending, (state, action) => {
      state.loading = true;
    });
    bd.addCase(updateProfileAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.serverError = undefined; // TO not forget
      // check every fields that might have server changed
      if (action.payload?.firstName) {
        state.firstName = action.payload.firstName;
      }
      if (action.payload?.lastName) {
        state.lastName = action.payload.lastName;
      }
      if (action.payload?.email) {
        state.email = action.payload.email;
      }
    });
    bd.addCase(updateProfileAsync.rejected, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.serverError = action.payload;
      } else {
        state.serverError = {
          errorKey: UNKNOWN_ERROR_KEY,
          submittedData: {}, // FIXME
        };
      }
    });
  },
});
