import React from 'react';
import {useTranslation} from '../../hooks/useTranslation';
import {Col, Container, Row} from 'react-bootstrap';
import logo from '../../assets/ionnyk_logo_black.png';

interface Props {}

export const PublicPaySuccess = ({}: Props) => {
  const t = useTranslation();

  return (
    <Container style={{padding: '25px'}}>
      <Row style={{marginBottom: '5rem'}}>
        <Col>
          <img
            style={{
              // image centering :
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            src={logo}
            height="30rem"
            alt="Ionnyk logo"
          />
        </Col>
      </Row>
      <Row>
        <Col
          md={{span: 6, offset: 3}}
          style={{padding: '10px', textAlign: 'center'}}>
          <h2>{t('pay_success_title')}</h2>
          <br />
          <br />
          <h4>{t('pay_success_subtitle')}</h4>
        </Col>
      </Row>
    </Container>
  );
};
