import {createSlice} from '@reduxjs/toolkit';
import {getAppLang} from '../../hooks/useTranslation';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    currentAppLang: getAppLang(
      // @ts-ignore
      window.navigator?.userLanguage || window.navigator?.language,
    ),
  },
  reducers: {
    switchLang: (state, action) => {
      state.currentAppLang = action.payload;
    },
  },
});
export const {switchLang} = appSlice.actions;
