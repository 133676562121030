import {
  EndReasonMap,
  PricingPeriod,
  ProductType,
  SubsAction,
} from '../../../redux/types';
import {dateStrToDate, dateStrToLocaleDate, diffDays} from '../../../utils';
import {Button, Card} from 'react-bootstrap';
import React from 'react';

interface BoxProps {
  prodType: ProductType;
  onClick: () => any | undefined | void;
  clickText: string;
  isIncluded: boolean;
  loading: boolean;
}

export const ProductDetail = ({
  prodType,
  isIncluded,
  onClick,
  clickText,
  loading,
}: BoxProps) => {
  // FIXME clean this mess
  const {pricings}: any = prodType;
  const theSub = prodType?.currentSubscription;
  const isPaid = !!pricings && !!Object.keys(pricings).length;
  const isActive = !!theSub?.active;
  const isTrial = prodType.actions?.includes(SubsAction.UPGRADE_TRIAL);
  const canTrial = prodType.actions?.includes(SubsAction.SUBSCRIBE_TRIAL);
  const addPaymentLegacy = prodType.actions?.includes(
    SubsAction.ADD_PAYMENT_METHOD,
  );
  const mustFix = prodType.actions?.includes(SubsAction.USER_ACTION_REQUIRED);
  const isPremiumSpecial = !isIncluded && !isPaid && isActive;
  const discount = isPaid
    ? (
        ((pricings[PricingPeriod.MONTHLY].price * 12.0 -
          pricings[PricingPeriod.YEARLY].price) /
          pricings[PricingPeriod.MONTHLY].price) *
        12.0
      ).toFixed(1)
    : 0;
  let remainingTrialDays = 0;
  if (isTrial) {
    const endTrialDate = dateStrToDate(theSub?.trialExpirationDate || '');
    const now = new Date();
    remainingTrialDays = diffDays(now, endTrialDate);
  }
  let subtitle = '';
  if (isPaid && isActive) {
    subtitle = 'Active - ';
    if (isTrial) {
      subtitle +=
        'trial ends: ' +
        dateStrToLocaleDate(theSub?.trialExpirationDate) +
        ' (' +
        remainingTrialDays +
        ' days)';
    }
    subtitle +=
      theSub?.nextBillingDate && !mustFix && !isTrial && !addPaymentLegacy
        ? 'next billing: ' + dateStrToLocaleDate(theSub?.nextBillingDate)
        : '';
    subtitle += theSub?.cancellationDate
      ? (isTrial ? '' : ' until ' + dateStrToLocaleDate(theSub?.endDate)) +
        ' (cancelled)'
      : '';
    subtitle += mustFix
      ? ' until ' + dateStrToLocaleDate(theSub?.endDate) + ' (payment required)'
      : '';
    subtitle += addPaymentLegacy
      ? ' until ' +
        dateStrToLocaleDate(theSub?.trialExpirationDate) +
        ' (payment required)'
      : '';
  } else if (isPaid && !isActive) {
    subtitle += 'Upgrade your experience ! ';
  } else if (!isPaid && isIncluded) {
    subtitle += 'Included in Discovery';
  } else if (!isPaid && isActive) {
    subtitle = loading ? 'Loading ...' : 'Active';
  }
  if (isPaid && !isActive && prodType?.lastSubscription?.endDate) {
    subtitle += `${
      EndReasonMap[prodType?.lastSubscription?.endReason || '']
    } the ${dateStrToLocaleDate(prodType?.lastSubscription.endDate)}`;
  }
  clickText = (() => {
    if (isIncluded && !isPaid) return 'Included';
    if (isPremiumSpecial && !addPaymentLegacy) return 'ACTIVE';
    if (isPremiumSpecial && addPaymentLegacy) return 'ADD PAYMENT';
    if (isPaid) return clickText;
    return clickText;
  })();

  return (
    <div
      style={{
        width: '40%',
        minWidth: '350px',
        display: 'flex',
        flexDirection: 'column',
      }}>
      {!!subtitle && (
        <span
          style={{
            marginTop: 15,
            marginBottom: 15,
            fontSize: 14,
            fontWeight: isActive ? 'bold' : 'normal',
            fontStyle: 'italic',
            textAlign: 'center',
          }}>
          {subtitle}
        </span>
      )}
      <Card
        style={{
          flex: 1,
          ...(isActive && {
            boxShadow: '5px 5px 30px 15px rgba(0,0,0,0.64)',
          }),
        }}>
        <Card.Img
          style={{textAlign: 'center'}}
          variant="top"
          src={prodType.media}
        />
        <Card.Body>
          <Card.Title>{prodType.name}</Card.Title>
          {clickText && (
            <Button
              style={{width: '100%'}}
              variant="primary"
              disabled={!isPaid || isPremiumSpecial}
              onClick={onClick}>
              {clickText}
            </Button>
          )}
        </Card.Body>
        {/**/}
        <Card.Body
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingTop: '0px',
          }}>
          <div>
            <Card.Text>
              <ul>
                {/*ts-ignore*/}
                {prodType.desc.map((d: any, index: any) => {
                  const isString = typeof d === 'string';
                  return isString ? (
                    <li key={index}>{d}</li>
                  ) : (
                    <li key={index}>
                      <ul
                        style={{
                          listStyle: 'thumbs',
                          listStylePosition: 'outside',
                          paddingTop: 10,
                          paddingBottom: 10,
                          // @ts-ignore
                        }}>
                        {d.map((s: any, index: number) => (
                          <li key={index} style={{fontSize: 12}}>
                            {s}
                          </li>
                        ))}
                      </ul>
                    </li>
                  );
                })}
                {(canTrial || isTrial) && (
                  <li>
                    <b>{prodType.trialDuration} days</b> of trial offered ! *
                  </li>
                )}
              </ul>
            </Card.Text>
          </div>
        </Card.Body>
        <Card.Footer>
          <p
            style={{
              width: '100%',
              textAlign: 'center',
              fontSize: 32,
              fontWeight: 'bold',
              marginBottom: 0,
            }}>
            {!isPaid ? (
              <>FREE</>
            ) : (
              `${pricings[PricingPeriod.MONTHLY].price} EUR / m.`
            )}
          </p>
          {isPaid && (
            <p style={{textAlign: 'center', fontSize: 18, marginBottom: 0}}>
              <b>{pricings[PricingPeriod.YEARLY].price} EUR / y. </b>
              <i style={{fontSize: 12}}>save {discount} % </i>
            </p>
          )}

          {(canTrial || isTrial || isPaid) && (
            <>
              <hr />
              <p style={{textAlign: 'center', fontSize: 12, margin: 0}}>
                {isPaid && (
                  <i style={{marginTop: 0}}>
                    {`* You can cancel your subscription anytime,  but you will be
                  charged for the current period (monthly/yearly${
                    canTrial || isTrial ? ', trial excluded' : ''
                  })`}
                  </i>
                )}
              </p>
            </>
          )}
        </Card.Footer>
      </Card>
    </div>
  );
};
