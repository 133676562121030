import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../stores/store';
import {
  APIErrorClient,
  authApi,
  handleNetworkError,
  initialRequestStatus,
  installBasicAuthHeader,
  RequestStatus,
  UNAUTHORIZED_STATUS,
  UNKNOWN_ERROR_KEY,
} from '../../api';
import {
  AuthUserDTOIn,
  SubLogin,
  SubscriptionItem,
  SubsType,
  UserSubscription,
} from '../types';
import {burnVoucher} from '../voucherSlice';

// Define a type for the slice state
interface AuthState extends RequestStatus {
  loggedIn: boolean;
  userId: number;
  email: string;
  emailConfirmed?: boolean;
  lastLoginDate?: Date;
  subscription?: SubLogin;
}

// Define the initial state using that type
const initialState: AuthState = {
  loggedIn: false,
  userId: -1,
  email: '',
  ...initialRequestStatus,
};

// generic types to benefit from rejectValue type in extra reducers
export const loginAsync = createAsyncThunk<
  AuthUserDTOIn, // output
  string, // input
  {rejectValue: APIErrorClient} // error output
>('auth/login', async (token: string, thunkAPI) => {
  try {
    installBasicAuthHeader(token); // install it first to override previous login
    const response = await authApi.get(`authorization`);
    const previousToken = localStorage.getItem('token');
    if (previousToken !== token) {
      localStorage.setItem('token', token);
    }
    const userDTO = response.data;
    return {
      ...userDTO,
      emailConfirmed: true, // FIXME onboarding not designed for this
      lastLoginDate: new Date().toJSON(), // FIXME true one + Date json() ? format ?
    } as AuthUserDTOIn; // to fulfilled reducer
  } catch (e) {
    return thunkAPI.rejectWithValue(
      handleNetworkError('loginAsync', e, token, [
        UNAUTHORIZED_STATUS,
      ]) as APIErrorClient,
    );
  }
});

export const signinSlice = createSlice({
  name: 'auth',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    logout:
      // {
      // reducer:
      (state) => {
        state.loggedIn = false;
        localStorage.removeItem('token'); // FIXME must be placed in action prepare()
        // },
        // prepare: () => localStorage.removeItem('token') as any, // FIXME fix prepare output type
      },
    reconfirmEmail: (state) => {
      state.emailConfirmed = false; // FIXME password reset
    },
  },
  extraReducers: (bd) => {
    // Add reducers for additional action types here, and handle loading state as needed
    // builder form to keep type checking
    bd.addCase(loginAsync.pending, (state, _) => {
      state.loading = true;
      state.serverError = undefined;
    });
    bd.addCase(loginAsync.fulfilled, (state, action) => {
      console.log('fulfilled', {state, action});
      if (action.payload) {
        state.loading = false;
        state.loggedIn = true;
        state.serverError = undefined;
        state.userId = action.payload.id;
        state.email = action.payload.email;
        state.emailConfirmed = action.payload.emailConfirmed;
        // @ts-ignore
        state.subscription = action.payload.subscription;
        state.lastLoginDate = action.payload.lastLoginDate;
      }
    });
    bd.addCase(burnVoucher.fulfilled, (state, action) => {
      if (action?.payload?.promotionType === 'PREMIUM_SUBSCRIPTION') {
        state.subscription = {
          startDate: new Date().toISOString(), // FIXME from server
          // @ts-ignore
          status: 'ACTIVE',
          subscriptionType: SubsType.DISCOVERY,
        };
      }
    });
    bd.addCase(loginAsync.rejected, (state, action) => {
      console.log('rejected', {state, action});
      if (action.payload) {
        state.loggedIn = false;
        state.loading = false;
        state.serverError = action.payload;
      } else {
        state.loggedIn = false;
        state.loading = false;
        state.serverError = {
          errorKey: UNKNOWN_ERROR_KEY,
          submittedData: {}, // FIXME
        };
      }
    });
  },
});

// export all actions
export const {logout, reconfirmEmail} = signinSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectLoggedIn = (state: RootState) => state.auth.loggedIn;
