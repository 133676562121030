import {SubsAction, SubsActionDTOIn, SubscriptionItem} from '../types';
import {authApi} from '../../api';

export const fetchSubs = async (): Promise<Partial<SubscriptionItem>[]> => {
  console.info('fetchSubs API');
  return (await authApi.get(`subscriptions`))?.data;
};

export const updateSubs = async (
  subscriptionType: string,
  action: SubsAction,
  pricingId?: string,
): Promise<SubsActionDTOIn> => {
  console.info('updateSubs API', {
    subscriptionType,
    action,
    pricingId,
  });
  return (
    await authApi.post(`subscriptions/${subscriptionType}`, {
      action,
      pricingId,
    })
  )?.data;
};
