import React, {useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/reduxTKHooks';
import {RootState} from '../../stores/store';
import {logout} from '../../redux/signinSlice';
import {switchLang} from '../../redux/appSlice';
import {Menu} from 'react-feather';
import {Button, Form, Navbar, NavDropdown, Overlay} from 'react-bootstrap';
import logo from '../../assets/ionnyk_logo_white.png';
import {AppLangs, useTranslation} from '../../hooks/useTranslation';
import {ResponsiveSideBar} from '../ResponsiveSideBar';
interface Props {}

export const AuthBar = ({}: Props) => {
  const language = useAppSelector(
    (state: RootState) => state.app.currentAppLang,
  );
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const t = useTranslation();

  return (
    <>
      <Navbar
        ref={target}
        style={{
          backgroundColor: 'black',
          // FIXME better responsive breakpoint
          ...(window.innerWidth > 400 && {
            paddingLeft: '2.5rem',
            paddingRight: '2.5rem',
            paddingTop: '1.5rem',
            paddingBottom: '1.5rem',
          }),
        }}>
        <Navbar.Brand href="/" className="mr-auto">
          <img
            src={logo}
            height="23rem"
            className="d-inline-block align-center"
            alt="Innoyk logo"
          />
        </Navbar.Brand>
        <Form inline>
          <NavDropdown
            title={language.toUpperCase()}
            id="basic-nav-dropdown"
            className="whitedropdown">
            <NavDropdown.Item
              href="#"
              onClick={() => dispatch(switchLang(AppLangs.fr))}>
              FR
            </NavDropdown.Item>
            <NavDropdown.Item
              href="#"
              onClick={() => dispatch(switchLang(AppLangs.en))}>
              EN
            </NavDropdown.Item>
          </NavDropdown>
          <Button
            variant="outline-info d-none d-md-block"
            onClick={() => dispatch(logout())}>
            {t('logout_btn')}
          </Button>
          <Button
            variant="outline-info"
            onClick={() => setShow(!show)}
            className="d-xs-block d-md-none d-lg-none">
            <Menu />
          </Button>
        </Form>
      </Navbar>
      {/* Menu responsive */}
      <Overlay target={target.current} show={show} placement="bottom-end">
        {({placement, arrowProps, show: _show, popper, ...props}) => (
          <div
            {...props}
            className="d-xs-block d-md-none d-lg-none" // hide on larger screen
            style={{
              width: '60%',
              ...props.style,
              transform: props.style.transform + ' translate3d(5px, 0px, 0)', // FIX
              zIndex: 999, // fix missing/wrong value
            }}>
            <ResponsiveSideBar
              hasLogout
              hasCloseButton
              onClose={() => setShow(false)}
            />
          </div>
        )}
      </Overlay>
    </>
  );
};
