import React from 'react';
import {useAppSelector} from '../../hooks/reduxTKHooks';
import {RootState} from '../../stores/store';
import {AuthProtector} from './AuthProtector';

interface Props {
  component: React.ComponentType<any>;
}

export const ProtectedRoute = ({component: ProtectedComponent}: Props) => {
  const loggedIn = useAppSelector((state: RootState) => state.auth.loggedIn);
  // conditional rendering
  return loggedIn ? <ProtectedComponent /> : <AuthProtector />;
};
