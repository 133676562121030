import React, {CSSProperties, ReactNode} from 'react';
import {ActionMap, ProductType, SubsAction} from '../../../redux/types';
import {ProductDetail} from './ProductDetail';

const PricingPanel = ({
  children,
  style,
}: {
  children: ReactNode; //ReactNode React.Component
  style: CSSProperties;
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        ...style,
      }}>
      {children}
    </div>
  );
};

export const ProductList = ({products, loading, doAction}: any) => {
  return (
    <PricingPanel
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        alignItems: 'stretch', // take all height for both siblings
      }}>
      {products?.map((product: ProductType) => {
        // FIXME improve
        let targetAction: SubsAction = SubsAction.NONE;
        if (product.actions?.includes(SubsAction.ADD_PAYMENT_METHOD)) {
          targetAction = SubsAction.ADD_PAYMENT_METHOD;
        } else if (product.actions?.includes(SubsAction.USER_ACTION_REQUIRED)) {
          targetAction = SubsAction.USER_ACTION_REQUIRED;
        } else if (product.actions?.includes(SubsAction.MANAGE)) {
          targetAction = SubsAction.MANAGE;
        } else if (product.actions?.includes(SubsAction.SUBSCRIBE_TRIAL)) {
          targetAction = SubsAction.SUBSCRIBE_TRIAL;
        } else if (product.actions?.includes(SubsAction.SUBSCRIBE)) {
          targetAction = SubsAction.SUBSCRIBE;
        }

        const onClick = () =>
          doAction(
            product.subscriptionType,
            targetAction,
            product?.pricings?.['MONTHLY']?.pricingId,
          );
        const targetText = loading ? 'Loading...' : ActionMap[targetAction];
        const isIncluded =
          (!product.pricings || !Object.keys(product.pricings).length) &&
          !!products.find(
            (s: ProductType) =>
              product.subscriptionType !== s?.subscriptionType &&
              s?.currentSubscription?.active, //&& !!s.pricings,
          );

        return (
          <ProductDetail
            loading={loading}
            key={product.subscriptionType}
            isIncluded={isIncluded}
            prodType={product}
            onClick={onClick}
            clickText={targetText}
          />
        );
      })}
    </PricingPanel>
  );
};
