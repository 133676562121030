import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {generateBasicToken, sleep} from '../../utils';
import {NewUser} from '../types';
import {loginAsync} from '../signinSlice';
import {
  anonApi,
  handleNetworkError,
  initialRequestStatus,
  UNKNOWN_ERROR_KEY,
} from '../../api';

export const signupAsync = createAsyncThunk(
  'auth/signup',
  async (newUser: NewUser, thunkAPI) => {
    try {
      const token = generateBasicToken(newUser.email, newUser.password); // FIXME firebaseToken and registration
      const response = await anonApi.post(`users/`, newUser);
      console.log(response.data);
      return await thunkAPI.dispatch(loginAsync(token));
    } catch (e) {
      return thunkAPI.rejectWithValue(
        handleNetworkError('loginAsync', e, newUser),
      );
    }
  },
);

export const signupSlice = createSlice({
  name: 'signup',
  initialState: {...initialRequestStatus},
  reducers: {},
  extraReducers: {
    // @ts-ignore
    [signupAsync.pending]: (state, action) => ({
      loading: true,
      serverError: undefined,
    }),
    // @ts-ignore
    [signupAsync.fulfilled]: (state, action) => ({
      loading: false,
      serverError: undefined,
    }),
    // @ts-ignore
    [signupAsync.rejected]: (state, action) => {
      if (action.payload) {
        return {loading: false, serverError: action.payload};
      } else {
        return {loading: false, serverError: {errorKey: UNKNOWN_ERROR_KEY}};
      }
    },
  },
});
