import React, {useEffect} from 'react';
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';
import {sortedPrivateRoutes} from './routes';
import {AuthBar} from '../components/AuthBar/AuthBar';
import {Col, Container, Navbar, Row} from 'react-bootstrap';
import {SideBar} from '../components/SideBar';

export default function Pages() {
  return (
    <Router>
      <Container fluid style={{height: '100%'}}>
        <Row>
          <Col
            style={{
              padding: 0, // remove padding for Overlay
            }}>
            <AuthBar />
          </Col>
        </Row>
        <Row style={{height: '100%'}}>
          <Col
            className="d-none d-md-block" // hide for small screen
            md={3}
            style={{
              padding: 0, // remove padding for borders
            }}>
            <SideBar />
          </Col>

          <Col md={9}>
            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL.
             NB: Children of Route are <Component/> !
             */}
            <Switch>
              {sortedPrivateRoutes.map((r: any) => (
                <Route key={r.key} path={r.path} exact={r.path === '/'}>
                  <r.Component />
                </Route>
              ))}
              <Route path="*">
                <div>Error 404</div>
              </Route>
            </Switch>
          </Col>
        </Row>
      </Container>
    </Router>
  );
}
