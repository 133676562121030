import {useAppSelector} from './reduxTKHooks';
import {RootState} from '../stores/store';
import {en, TKeys} from '../i18n/en';
import {fr} from '../i18n/fr';
import React from 'react';

export enum AppLangs {
  fr = 'fr',
  en = 'en',
}

export const useTranslation = () => {
  const language = useAppSelector(
    (state: RootState) => state.app.currentAppLang,
  );

  // to memoize if language did not change, as every screens will have them
  return React.useCallback(
    (key?: TKeys) => {
      if (!key) return '';
      const fallback = en[key] || key;
      switch (language) {
        case AppLangs.fr:
          return fr[key] || fallback;
        case AppLangs.en:
          return en[key] || fallback;
        default:
          return fallback;
      }
    },
    [language],
  );
};

/*
   'fr-FR':
   'fr-BE':
   'en-US':
   'en-UK':
 */

export const getAppLang = (sysLang: string = 'en') =>
  Object.values(AppLangs).find((ln) => sysLang.toLowerCase().startsWith(ln)) ||
  AppLangs.en;
