import {TKeys} from './en';
import {CLIENT_BAD_REQUEST_STATUS_STRING, UNKNOWN_ERROR_KEY} from '../api';

export const fr: Record<TKeys, string> = {
  subs_title: 'Gestion de votre abonnement',
  // NAVS
  Home: 'Accueil',
  Voucher: 'Activation Code',
  Profile: 'Profile',
  Subs: 'Abonnements',
  Login: 'Login',
  SignUp: 'Inscription',
  // SCREENS
  onboarding_title: 'Bienvenue sur votre compte Ionnyk!',
  onboarding_s1_title: '1. Validez votre adresse e-mail',
  onboarding_s1_body:
    'Veuillez ouvrir votre client de messagerie, vérifier le dossier spam si nécessaire et cliquer sur le lien fourni. Mail non reçu?',
  onboarding_s2_title: '2. Activez votre bon (le cas échéant)',
  onboarding_s2_body:
    'Si vous avez reçu un bon avec votre cadre Ionnyk et que vous souhaitez activer vos actifs numériques.',
  onboarding_s3_title: "3. Installez et connectez-vous à l'application Ionnyk!",
  onboarding_s3_subtitle:
    "Utilisez l'application Ionnyk pour gérer votre cadre",
  onboarding_s3_body:
    "Parcourez la galerie, créez des listes de lecture, modifiez les images en fonction des paramètres et des humeurs ou achetez vos œuvres d'art préférées ...",
  profile_title: 'Votre profil',
  profile_empty: 'Sélectionner un champ',
  voucher_title: 'Activer un bon',
  // BUTTONS
  signin_btn: 'Se connecter',
  signup_btn: "S'inscrire",
  save_btn: 'Sauver',
  register_btn: "S'inscrire",
  apply_btn: 'Activer',
  use_voucher_btn: 'Utiliser le voucher',
  sub_upgrade_title: 'Changer votre forfait', // BUTTONS
  logout_btn: 'Déconnexion',
  resend_btn: 'Renvoyer un lien',
  skip_btn: 'PASSER',
  close_btn: 'Fermer',
  gobacklogin_btn: 'Déjà utilisateur? Veuillez vous connecter ici',
  forgotpsw_btn: "J'ai oublié mon mot de passe",
  email_to_confirm:
    "Cette adresse e-mail n'a pas encore été confirmée. Veuillez ouvrir le lien reçu.",
  // FORMS
  email_pl: 'Entrer votre email',
  email_hint: 'Nous ne partagerons jamais votre e-mail avec qui que ce soit.',
  email_label: 'Adresse email',
  psw_pl: 'Mot de passe',
  psw_label: 'Mot de passe',
  psw_hint:
    'Au moins une lettre majuscule, une lettre minuscule, un chiffre, et entre 6 et 32 caractères',
  psw_repeat_label: 'Répéter le mot de passe',
  psw_current_label: 'Mot de passe actuel',
  firstname_pl: 'Prénom',
  firstname_label: 'Prénom',
  lastname_pl: 'Nom',
  lastname_label: 'Nom',
  invalid_login: 'E-mail et / ou mot de passe invalide. Veuillez réessayer',
  voucher_hint: "Veuillez noter qu'il ne peut être utilisé qu'une seule fois.",
  voucher_label: 'Veuillez saisir votre bon',
  // FORM validation
  val_misc_format: 'Veuillez vérifier que le format est correct',
  val_non_empty: 'Ne doit pas être vide',
  val_psw_strength:
    'Veuillez vérifier que les mots de passe correspondent. Doit contenir au moins une lettre majuscule, une lettre minuscule, un chiffre et entre 6 et 32 caractères ',
  // General API Error
  [CLIENT_BAD_REQUEST_STATUS_STRING]:
    'Veuillez vérifier les données fournies (champ manquant, trop long ou trop court ou format incorrect)',
  // Voucher api errors
  VOUCHER_SUCCESS: 'Succès',
  PREMIUM_SUBSCRIPTION: "L'abonnement Premium a été activé avec succès!",
  VOUCHER_NOT_FOUND: 'Bon non trouvé',
  VOUCHER_EXPIRED: "Le bon n'est plus utilisable (expiré)",
  VOUCHER_ALREADY_USED: 'Le bon a déjà été utilisé',
  PROMOTION_NOT_APPLICABLE: "La promotion n'est pas applicable",
  // Signup api errors
  EMAIL_ALREADY_USED:
    'Un compte existe déjà pour cet e-mail. Veuillez vous connecter à la place',
  WEAK_PASSWORD: "Le mot de passe fourni n'est pas assez fort",
  AUTH_FAILED: 'Le mot de passe donné ne correspond pas au mot de passe actuel',
  INVALID_CAPTCHA: 'Captcha non valide, veuillez réessayer plus tard',
  [UNKNOWN_ERROR_KEY]:
    'Erreur de serveur inconnue, veuillez réessayer plus tard',
  // Profile api
  profile_updated: 'Le profil a été mis à jour avec succès.',
  // Public
  page_loading: 'Chargement ...',
  pay_cancel_title: 'Votre paiement a été annulé',
  pay_cancel_subtitle:
    'Continuez à parcourir le catalogue et reprenez votre achat plus tard.',
  pay_success_title: 'Félicitations !',
  pay_success_subtitle:
    'Votre paiement a été confirmé. Vous allez TRES BIENTOT trouver et pouvoir afficher votre nouvelle oeuvre depuis votre Vault. Ceci peut prendre quelques minutes.',
  forgot_psw_title: 'Mot de passe oublié',
  reset_psw_title: 'Nouveau mot de passe',
  submit_btn: 'Soumettre',
  email_to_send: 'Vous allez recevoir un email',
  invalid_link: 'Le lien est invalide',
  expired_link: 'Le lien est expiré',
  psw_bad_format:
    "Le mot de passe n'est pas valide (robustesse). Veuillez réeassayer",
  psw_unk_error:
    'Echec de mise à jour du mot de passe. Veuillez réeassayer plus tard',
  no_token: 'Token absent',
  success_reset:
    'Mot de passe changé avec succès ! Vous allez être redirigé sur le login',
  current_sub: 'Abonnement actif: ',
  freemium: '"Taste of Ionnyk"',
  premium: '"Discovery"',
};
