import React from 'react';
import {Container} from 'react-bootstrap';
import {useTranslation} from '../../hooks/useTranslation';
interface Props {}

// Blank page during public/private resolution
// FIXME appears also for login page ...
export const PublicLoading = ({}: Props) => {
  const t = useTranslation();
  return (
    <Container style={{padding: '25px'}}>
      {/*<h2>{t('page_loading')}</h2>*/}
    </Container>
  );
};
