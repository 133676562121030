import React from 'react';
import {Nav} from 'react-bootstrap';
import {sortedPrivateRoutes} from '../../pages/routes';
import {NavLink} from 'react-router-dom';
import {logout} from '../../redux/signinSlice';
import {useAppDispatch} from '../../hooks/reduxTKHooks';
import {useTranslation} from '../../hooks/useTranslation';

interface Props {
  isResponsive?: boolean;
  hasLogout?: boolean;
  hasCloseButton?: boolean;
  onClose?: () => void;
}

export const ResponsiveSideBar = ({
  hasLogout = false,
  hasCloseButton = false,
  onClose,
}: Props) => {
  const dispatch = useAppDispatch();
  const t = useTranslation();

  return (
    <Nav
      style={{
        borderStyle: 'solid',
        borderWidth: 1,
        margin: 2,
        height: '100%', // FIXME take full height and sticky
        padding: '10px',
      }}
      className={`flex-column flex-fill bg-dark`}
      onSelect={(selectedKey) => console.log(`selected ${selectedKey}`)}>
      {hasCloseButton && (
        <Nav.Link
          style={{
            color: 'lightgrey',
            fontSize: 35,
            fontFamily: 'Montserrat',
            textAlign: 'right',
            marginRight: '1rem',
          }}
          className="nav-link float-right"
          href="#"
          onClick={() => onClose && onClose()}>
          ×{/*<hr style={{backgroundColor: 'lightgrey'}} />*/}
        </Nav.Link>
      )}
      {sortedPrivateRoutes.map((r: any) => (
        <NavLink
          key={r.key}
          className="nav-link"
          style={{color: 'lightgrey', fontSize: 35, fontFamily: 'Montserrat'}}
          activeStyle={{
            fontWeight: 'bold',
            color: 'lightgrey',
          }}
          exact
          to={r.path}>
          {t(r.label)}
        </NavLink>
      ))}

      {hasLogout && (
        <Nav.Link
          href="#"
          className="nav-link"
          style={{
            textDecoration: 'underline',
            color: 'lightgrey',
            fontFamily: 'Montserrat',
            fontSize: 20,
          }}
          onClick={() => dispatch(logout())}>
          {t('logout_btn')}
        </Nav.Link>
      )}
    </Nav>
  );
};
